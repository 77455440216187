import { ThemeType } from 'grommet';
import { css } from 'styled-components';

export const colors = {
	orange: '#faa100',
	secondary: '#7dc4e8',
	dark3: 'rgb(34, 34, 34)',
	white: '#ffffff',
	black: '#1c1e21',
	green: '#50c88e',
	lightGreen: '#e5f7ef',
	lightOrange: '#feecca',
	blue: '#3b5998',
	softOrange: '#fef4ea',
	opacityGreen: 'rgba(80, 200, 142, 0.2)',
};

const borderRadius = {
	primary: '24px',
};

export const grommetTheme: ThemeType = {
	global: {
		font: {
			size: '16px',
			family: 'Inter, sans-serif',
		},
		colors: {
			text: {
				light: colors.dark3,
			},
			white: colors.white,
			orange: colors.orange,
			green: colors.green,
			lightGreen: colors.lightGreen,
			lightOrange: colors.lightOrange,
			'dark-3': colors.dark3,
			blue: colors.blue,
			softOrange: colors.softOrange,
			opacityGreen: colors.opacityGreen,
		},
		drop: {
			zIndex: '20',
		},
		focus: {
			border: {
				color: 'brand',
			},
		},
		control: { border: { radius: borderRadius.primary } },
		breakpoints: {
			small: {
				value: 512,
				borderSize: { xsmall: '1px', small: '2px', medium: '4px', large: '6px', xlarge: '12px' },
				edgeSize: {
					none: '0px',
					hair: '1px',
					xxsmall: '2px',
					xsmall: '3px',
					small: '6px',
					medium: '12px',
					large: '24px',
					xlarge: '48px',
				},
				size: {
					xxsmall: '24px',
					xsmall: '48px',
					small: '96px',
					medium: '192px',
					large: '384px',
					xlarge: '768px',
					full: '100%',
				},
			},
			medium: { value: 1024 },
			large: {},
		},
	},
	accordion: {
		panel: {
			border: {
				color: 'transparent',
			},
		},
		border: {
			color: 'transparent',
		},
	},
	text: {
		small: {
			size: '14px',
		},
		medium: {
			size: '16px',
		},
	},
	button: {
		color: 'dark-3',
		border: { radius: borderRadius.primary },
		size: { medium: { pad: { vertical: '8px' } } },
	},
	layer: { border: { radius: borderRadius.primary } },
	tab: {
		active: {
			color: 'brand',
		},
		hover: {
			color: undefined,
		},
		margin: undefined,
		pad: {
			bottom: undefined,
			vertical: 'small',
		},
		extend: () =>
			css`
				color: ${colors.dark3};
			`,
	},
	tabs: {
		header: {
			border: {
				size: '1px',
				color: 'lightGrey',
				side: 'bottom',
			},
			extend: () => css`
				justify-content: flex-start;
				border-top-left-radius: 50%;
				border-top-right-radius: 50%;
			`,
		},
	},
};
