import { ThemeType } from 'grommet';
import { colors, grommetTheme } from 'src/styles/grommetTheme';
import { deepMerge } from 'grommet/utils';

export type Theme = ThemeType;

export const theme: Theme = {
	...grommetTheme,
};

export const sliderTheme = deepMerge(theme, {
	button: {
		color: 'white',
	},
});

export const customerTheme = deepMerge(theme, {
	global: {
		colors: {
			brand: colors.green,
			brandLight: colors.lightGreen,
		},
	},
});

export const taskerTheme: Theme = deepMerge(theme, {
	global: {
		colors: {
			brand: colors.orange,
			brandLight: colors.lightOrange,
		},
	},
});
