import { Box, Heading } from 'grommet';
import React, { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { NotificationsDropdownWrapper } from './NotificationsDropdown.styled';
import NotificationsList from './NotificationsList/NotificationsList';

interface Props {
	isShown: boolean;
	scrollStarted: boolean;
	handleExit(): void;
}

const NotificationsDropdown: React.FC<Props> = ({ isShown, scrollStarted, handleExit }) => {
	const ref = useRef<HTMLDivElement>(null);

	const duration = 200;

	const defaultStyle = {
		transition: `all ${duration}ms ease-in-out`,
		opacity: 0,
		top: 0,
	};

	const transitionStyles = {
		entering: { opacity: 1, top: '65px' },
		entered: { opacity: 1, top: '65px' },
		exiting: { opacity: 0, top: '50px' },
		exited: { opacity: 0, top: '50px' },
		unmounted: { opacity: 0, top: '50px' },
	};

	const handleClickOutsideWrapper: EventListener = (e: Event) => {
		if (ref.current && !ref.current.contains(e.target as any)) {
			handleExit();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideWrapper);
		return () => document.removeEventListener('mousedown', handleClickOutsideWrapper);
	});

	return (
		<Transition in={isShown} timeout={duration}>
			{(state) => (
				<NotificationsDropdownWrapper
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}
					round="small"
					ref={ref}
					isShown={isShown}
					scrollStarted={scrollStarted}
				>
					{isShown && (
						<Box pad="xsmall">
							<Heading level={3} size="small" margin={{ left: 'small', top: 'small' }}>
								Notifications
								<br />
							</Heading>
							<NotificationsList />
						</Box>
					)}
				</NotificationsDropdownWrapper>
			)}
		</Transition>
	);
};

export default NotificationsDropdown;
