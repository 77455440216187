import styled from 'styled-components';
import { animated } from 'react-spring';
import { Box } from 'grommet';

export const AnimatedListBox = styled(Box)`
	position: relative;
`;

export const AnimatedListWrapper = styled(animated.div)`
	width: 100%;
	position: absolute;
	will-change: transform, height, opacity;
	box-sizing: border-box;
`;
