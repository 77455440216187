import { Box, Text } from 'grommet';
import {
	ChatOption as ChatOptionIcon,
	ContactInfo as ContactInfoIcon,
	Logout as LogoutIcon,
	Multiple as OrdersIcon,
	Notification as NotificationIcon,
	User as UserIcon,
	Workshop as WorkshopIcon,
	CreditCard as CreditCardIcon,
	StatusGood,
	StatusCritical,
} from 'grommet-icons';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import {
	AvatarWrapper,
	CreateOrderButton,
	LinkItem,
	Profile,
	ProfileItem,
	ProfileMenu,
	SwitchClientAccount,
	SwitchTaskerAccount,
} from 'src/components/Header/LoginToolbar/LoginToolbar.styled';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TaskerVerificationType, UserContext, UserType } from 'src/providers/UserProvider';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import { ModalType } from 'src/types/Auth.types';
import { profileImageGenerator } from 'src/utils/profileImageGenerator';
import NotificationsDropdown from './NotificationsDropdown/NotificationsDropdown';
import { NotificationDot } from 'src/components/NotificationDot/NotificationDot.styled';
import { Screen, useMedia } from '../../../utils/useMedia';

interface Props {
	scrollStarted: boolean;
	onItemClick?(item?: string): void;
}

export const LoginToolbar: React.FC<Props> = ({ scrollStarted, onItemClick }) => {
	const [showProfileMenu, setShowProfileMenu] = useState(false);
	const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const { t } = useTranslation(['header']);
	const history = useHistory();
	const {
		state: { data },
		isCustomer,
		toggleUserType,
		signOut,
	} = useContext(UserContext);
	const { showModalWindow } = useContext(ModalWindowContext);
	const isTablet = useMedia(Screen.tablet);

	const menuItems = [
		{
			icon: <UserIcon />,
			id: 'profile',
			onClick: () => history.push(`/user/${data?.id}`),
		},
		{
			icon: <OrdersIcon />,
			id: isCustomer() ? 'orders' : 'ordersAndRequests',
			onClick: () => history.push('/orders'),
		},
		{
			icon: <ChatOptionIcon />,
			id: 'chats',
			onClick: () => history.push('/chats'),
		},
		{
			icon: <CreditCardIcon />,
			id: isCustomer() ? 'payments' : 'earnings',
			onClick: () => history.push('/finances'),
		},
		{
			icon: <LogoutIcon />,
			id: 'logOut',
			onClick: () => signOut(),
		},
	];

	const duration = 200;

	const defaultStyle = {
		transition: `all ${duration}ms ease-in-out`,
		opacity: 0,
		top: 0,
	};

	const transitionStyles = {
		entering: { opacity: 1, top: '65px' },
		entered: { opacity: 1, top: '65px' },
		exiting: { opacity: 0, top: '50px' },
		exited: { opacity: 0, top: '50px' },
		unmounted: { opacity: 0, top: '50px' },
	};

	const handleClickOutside: EventListener = (e: Event) => {
		if (ref.current && !ref.current.contains(e.target as any)) {
			setShowProfileMenu(false);
		}
	};

	const linkToCreateOrder = () => {
		history.push('/create-order');
		onItemClick && onItemClick();
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	const switchToTasker = () => {
		if (data?.categories.length) {
			toggleUserType(UserType.tasker);
		} else {
			showModalWindow({ type: ModalType.addTaskerProfile });
		}
	};

	return (
		<Fragment>
			{isCustomer() && !isTablet && (
				<CreateOrderButton
					label="Create order"
					onClick={linkToCreateOrder}
					margin={{ right: isTablet ? 'none' : 'small' }}
				/>
			)}
			{!isTablet && (
				<LinkItem margin={{ right: 'medium' }}>
					<NotificationDot isShown={data?.newNotifications}>
						<NotificationIcon
							onClick={() => {
								setShowNotificationsDropdown(true);
							}}
						/>
					</NotificationDot>
				</LinkItem>
			)}
			{!isTablet && (
				<LinkItem margin={{ right: 'medium' }}>
					<ChatOptionIcon
						onClick={() => {
							history.push('/chats');
						}}
					/>
				</LinkItem>
			)}
			<Profile>
				<Box
					direction="row"
					align={isTablet ? 'start' : 'center'}
					onClick={() => setShowProfileMenu(true)}
				>
					<AvatarWrapper
						isActive={showProfileMenu}
						size="medium"
						src={profileImageGenerator(data?.id)}
					/>
					<Box margin={{ left: 'small', bottom: isTablet ? 'large' : 'none' }} direction="column">
						<Box direction="row" gap="xsmall">
							<Text size="small" color={isTablet ? 'white' : 'black'}>
								{isCustomer() ? 'Customer' : 'Tasker'}
							</Text>
							{!isCustomer() && (
								<Box justify="center">
									{data?.taskerVerification === TaskerVerificationType.verified ? (
										<StatusGood size="14px" color="green" />
									) : (
										<StatusCritical size="14px" color="red" />
									)}
								</Box>
							)}
						</Box>
						<Text color={isTablet ? 'white' : 'black'}>
							{data?.firstName} {data?.lastName}
						</Text>
					</Box>
				</Box>
				<NotificationsDropdown
					isShown={showNotificationsDropdown}
					handleExit={() => setShowNotificationsDropdown(false)}
					scrollStarted={scrollStarted}
				/>
				<Transition in={showProfileMenu || isTablet} timeout={duration}>
					{(state) => (
						<ProfileMenu
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}
							round="small"
							ref={ref}
						>
							{(showProfileMenu || isTablet) && (
								<Fragment>
									<Box direction="row" margin={{ vertical: 'small', horizontal: 'small' }}>
										<SwitchClientAccount
											selected={isCustomer()}
											onClick={() => toggleUserType(UserType.customer)}
										>
											<WorkshopIcon />
											<Text margin={{ left: 'small' }}>Customer</Text>
										</SwitchClientAccount>
										<SwitchTaskerAccount selected={!isCustomer()} onClick={switchToTasker}>
											<ContactInfoIcon />
											<Text margin={{ left: 'small' }}>Tasker</Text>
										</SwitchTaskerAccount>
									</Box>
									{menuItems.map((menuItem) => (
										<ProfileItem
											key={menuItem.id}
											direction="row"
											pad={{
												left: 'small',
												right: 'large',
												vertical: isTablet ? 'medium' : 'small',
											}}
											onClick={() => {
												menuItem.onClick();
												onItemClick && onItemClick();
											}}
										>
											<Box margin={{ right: 'small' }}>{menuItem.icon}</Box>
											{t(`header:menu.${menuItem.id}`)}
										</ProfileItem>
									))}
								</Fragment>
							)}
						</ProfileMenu>
					)}
				</Transition>
			</Profile>
		</Fragment>
	);
};

export default LoginToolbar;
