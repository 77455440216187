import React from 'react';
import { useTransition } from 'react-spring';
import { AnimatedListBox, AnimatedListWrapper } from './AnimatedList.styled';

interface Props {
	children: any;
	items: Array<any>;
	itemHeight?: number;
	gap?: number;
}

const AnimatedList: React.FC<Props> = ({ children, items, itemHeight = 800, gap = 15 }) => {
	// Declare animation
	const transitions = useTransition(
		items.map((item: any, i: number) => ({
			...item,
			y: i * (itemHeight + gap),
			height: itemHeight,
		})),
		{
			key: (item: { id: string; height: number }) => item.id,
			from: { opacity: 0 },
			leave: { opacity: 0 },
			enter: ({ y, height }) => ({ y, height, itemHeight, opacity: 1 }),
			update: ({ y, height }) => ({ y, height, itemHeight }),
		},
	);

	return (
		<AnimatedListBox height={(itemHeight + gap) * items.length + 'px'}>
			{transitions((style, item, t, index) => (
				<AnimatedListWrapper
					style={{
						zIndex: items.length - index,
						...style,
					}}
				>
					{children(item)}
				</AnimatedListWrapper>
			))}
		</AnimatedListBox>
	);
};

export default AnimatedList;
