import React, { createContext, useCallback, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { LocalStorage, removeItemFromLocalStorage } from 'src/utils/localStorage';
import { useApolloClient, useMutation } from '@apollo/client';
import { GET_USER } from 'src/graphql/user/queries';
import { SET_USER_IS_CUSTOMER } from 'src/graphql/user/mutations';

interface Action {
	type: string;
	payload?: any;
}

interface UserData {
	id: string;
	description: string;
	email: string;
	categories: string[];
	location: string;
	birthdate: string;
	firstName: string;
	lastName: string;
	newNotifications: boolean;
	taskerVerification: TaskerVerificationType;
}

interface State {
	type: UserType;
	loadingUser: boolean;
	isLoggedIn: boolean;
	data?: UserData;
}

export enum UserType {
	customer = 'customer',
	tasker = 'tasker',
}

export enum TaskerVerificationType {
	notVerified = 'notVerified',
	inProgress = 'inProgress',
	pending = 'pending',
	verified = 'verified',
	failed = 'failed',
}

interface UserContextInterface {
	state: State;
	isCustomer(): boolean;
	toggleUserType(type?: UserType): void;
	setLoadingUser(load: boolean): void;
	checkUserAuth(): Promise<void>;
	signOut(): void;
	setUserData(payload: Partial<UserData>): void;
}

const defaultState: State = {
	type: UserType.customer,
	loadingUser: true,
	isLoggedIn: false,
};

export const UserContext = createContext<UserContextInterface>({
	state: defaultState,
	isCustomer(): boolean {
		return true;
	},
	toggleUserType(): void {},
	setLoadingUser(): void {},
	checkUserAuth(): Promise<void> {
		return new Promise(() => {});
	},
	signOut(): void {},
	setUserData(): void {},
});

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'TOGGLE_USER_TYPE':
			return {
				...state,
				type:
					action.payload ||
					(state.type === UserType.customer ? UserType.tasker : UserType.customer),
			};
		case 'SET_USER_LOADING':
			return { ...state, loadingUser: action.payload };
		case 'SET_IS_LOGGED_IN':
			return {
				...state,
				isLoggedIn: action.payload.isLoggedIn,
				type:
					action.payload.isLoggedIn && !action.payload.isCustomer
						? UserType.tasker
						: UserType.customer,
			};
		case 'SET_IS_LOGGED_OUT':
			return {
				...defaultState,
				loadingUser: false,
			};
		case 'SET_USER_DATA':
			return {
				...state,
				data: { ...state.data, ...action.payload },
			};
		default:
			return state;
	}
};

export const UserProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState);
	const history = useHistory();
	const client = useApolloClient();
	const [setUserIsCustomer] = useMutation(SET_USER_IS_CUSTOMER);

	const isCustomer = useCallback(() => {
		return state.type === UserType.customer;
	}, [state.type]);

	const setLoadingUser = useCallback((load: boolean) => {
		dispatch({ type: 'SET_USER_LOADING', payload: load });
	}, []);

	const toggleUserType = useCallback(
		async (type: UserType) => {
			if (type) {
				await setUserIsCustomer({
					variables: {
						input: {
							isCustomer: type === UserType.customer,
						},
					},
				});
			} else {
				await setUserIsCustomer({
					variables: {
						input: {
							isCustomer: state.type === UserType.customer,
						},
					},
				});
			}
			dispatch({ type: 'TOGGLE_USER_TYPE', payload: type });
		},
		[state.type, setUserIsCustomer],
	);

	const checkUserAuth = useCallback(async () => {
		try {
			setLoadingUser(true);
			const { data: user } = await client.query({
				query: GET_USER,
				fetchPolicy: 'no-cache',
			});
			const attributes = user.getUser;
			dispatch({
				type: 'SET_USER_DATA',
				payload: {
					id: attributes.id,
					description: attributes.description,
					location: attributes.location,
					categories: attributes.categories,
					firstName: attributes.firstName,
					lastName: attributes.lastName,
					birthdate: attributes.birthdate,
					newNotifications: attributes.newNotifications,
					taskerVerification: attributes.taskerVerification,
				},
			});
			dispatch({
				type: 'SET_IS_LOGGED_IN',
				payload: { isLoggedIn: true, isCustomer: attributes.isCustomer },
			});
		} catch (e) {
			dispatch({ type: 'SET_IS_LOGGED_IN', payload: { isLoggedIn: false } });
		} finally {
			setLoadingUser(false);
		}
	}, [setLoadingUser, client]);

	const signOut = useCallback(async () => {
		try {
			removeItemFromLocalStorage(LocalStorage.Order);
			await Auth.signOut();
			dispatch({ type: 'SET_IS_LOGGED_OUT' });
			history.push('/');
		} catch (e) {
			console.error(e);
		}
	}, [history]);

	const setUserData = useCallback((payload: Partial<UserData>) => {
		dispatch({ type: 'SET_USER_DATA', payload });
	}, []);

	const context: UserContextInterface = {
		state,
		isCustomer,
		toggleUserType,
		setLoadingUser,
		checkUserAuth,
		signOut,
		setUserData,
	};

	return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};
