import { gql } from '@apollo/client';

export const UPDATE_TASKER_PROFILE = gql`
	mutation updateTaskerProfile($input: UpdateTaskerProfileInput!) {
		updateTaskerProfile(input: $input) {
			user {
				id
				categories
			}
			profile {
				id
				userId
				category
				location
				birthdate
				firstName
				lastName
				rating
				numberOfReviews
				rate
				description
				portfolioImages
				taskerVerification
			}
		}
	}
`;

export const REMOVE_TASKER_PROFILE = gql`
	mutation removeTaskerProfile($input: RemoveTaskerProfileInput!) {
		removeTaskerProfile(input: $input) {
			user {
				id
				categories
			}
			profile {
				id
			}
		}
	}
`;

export const REMOVE_PORTFOLIO_IMAGE = gql`
	mutation removePortfolioImage($input: RemovePortfolioImageInput!) {
		removePortfolioImage(input: $input) {
			id
			portfolioImages
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			description
			location
			birthdate
			firstName
			lastName
			email
		}
	}
`;

export const SET_USER_IS_CUSTOMER = gql`
	mutation setUserIsCustomer($input: SetUserIsCustomerInput!) {
		setUserIsCustomer(input: $input) {
			id
			isCustomer
		}
	}
`;
