import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, ThemeType, Text } from 'grommet';
import FulfillingBouncingCircleSpinner from 'src/components/Spinner/Spinner';
import { useTheme } from 'styled-components';
import AnimatedList from 'src/components/AnimatedList/AnimatedList';
import { debounce } from 'src/utils/debounce';
import NotificationsListItem from './NotificationsListItem/NotificationsListItem';
import { GET_NOTIFICATIONS } from 'src/graphql/notification/queries';
import { INotification } from 'src/graphql/notification/types';

const NotificationsList: React.FC = () => {
	// Declare general
	const theme: ThemeType = useTheme();

	// Declare GraphQl queries and mutations
	const { data, loading, fetchMore } = useQuery(GET_NOTIFICATIONS, {
		variables: {
			limit: 4,
		},
		// This is needed to rebuild the cache on the first component render (look at ApolloCache.ts)
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
	});

	// Declare on... actions
	const scrollOnMore = (e: any) => {
		const bottom =
			(100 * e.target.scrollTop) / (e.target.scrollHeight - e.target.clientHeight) >= 70;
		if (bottom) {
			if (data.getNotifications.nextToken) {
				debounce(fetchMore({ variables: { nextToken: data.getNotifications.nextToken } }), 2000);
			}
		}
	};

	return (
		<Box direction="column" gap="medium" fill>
			{loading && !data ? (
				<Box align="center" justify="center" height="full">
					<FulfillingBouncingCircleSpinner color={theme.global?.colors?.brand} size={75} />
				</Box>
			) : (
				<Box fill>
					{data.getNotifications.notifications.length ? (
						<Box direction="column" overflow="scroll" onScroll={scrollOnMore}>
							<AnimatedList items={data.getNotifications.notifications} itemHeight={180} gap={30}>
								{(notification: INotification) => (
									<NotificationsListItem notification={notification} />
								)}
							</AnimatedList>
						</Box>
					) : (
						<Box fill align="center" justify="center">
							<Text>You have no notifications</Text>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
};

export default NotificationsList;
