import { Box, Text } from 'grommet';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomAvatar } from 'src/components/StripeWithCircle/StripeWithCircle.styled';
import { INotification } from 'src/graphql/notification/types';
import { profileImageGenerator } from 'src/utils/profileImageGenerator';
import { NotificationsListItemWrapper } from './NotificationsListItem.styled';

interface Props {
	notification: INotification;
}

const NotificationsListItem: React.FC<Props> = ({ notification }) => {
	const event = JSON.parse(notification.event);
	const history = useHistory();

	const renderComponent = (type: string) => {
		switch (type) {
			case 'request':
				return {
					handleClick: () => history.push(`/order/${event.id}`),
					component: (
						<Box direction="row">
							<CustomAvatar
								color="brand"
								size="medium"
								src={profileImageGenerator(event.taskerId)}
							/>
							<Text margin={{ left: 'small' }}>Tasker submitted your order {event.title}.</Text>
						</Box>
					),
				};
			default:
				return { handleClick: () => {}, component: null };
		}
	};

	return (
		<NotificationsListItemWrapper
			round="small"
			pad="xsmall"
			onClick={renderComponent(notification.eventType).handleClick}
		>
			{renderComponent(notification.eventType).component}
		</NotificationsListItemWrapper>
	);
};

export default NotificationsListItem;
