import { Order } from 'src/types/Order.types';
import { FormRequest } from 'src/types/FormRequest.types';

export enum LocalStorage {
	Order = 'order',
	Request = 'request',
	AcceptRequest = 'acceptRequest',
}

type LocalStorageObject = Partial<FormRequest> | Partial<Order> | null;

// LocalStorage utils
export const setItemToLocalStorage = (property: string, item: Record<string, any>): void =>
	localStorage.setItem(property, JSON.stringify(item));

export const updateItemInLocalStorage = (property: string, item: Record<string, any>): void => {
	const itemFromLocalStorage = getItemFromLocalStorage(property);
	if (itemFromLocalStorage) {
		setItemToLocalStorage(property, { ...itemFromLocalStorage, ...item });
	} else {
		setItemToLocalStorage(property, item);
	}
};

export const removeItemFromLocalStorage = (property: string): void =>
	localStorage.setItem(property, '');

export const getItemFromLocalStorage = (property: string): LocalStorageObject => {
	const itemFromLocalStorage = localStorage.getItem(property);
	if (itemFromLocalStorage) {
		return JSON.parse(itemFromLocalStorage);
	} else {
		return null;
	}
};
