// global styles with types
import { Anchor, Box, Text, Main } from 'grommet';
import styled, { createGlobalStyle } from 'styled-components';

interface Props {
	isTablet: boolean;
}

export const GlobalStyle = createGlobalStyle`    
html, body, #root, #root>div {
  min-height: 100vh;
  margin: 0;
  font-weight: 500;
}
#root>div {
  display: flex;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
}

label {
 position: relative;
}
`;

export const MainContainer = styled(Main)<Props>`
	padding-top: ${({ isTablet }) => (isTablet ? '68px' : '96px')};
	height: auto;
`;

export const CursorPointer = styled(Box)`
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme?.global?.colors?.brand};
	}
`;

export const UnderlineText = styled(Text)`
	text-decoration: underline;
`;

export const UnderlineLink = styled(Anchor)`
	text-decoration: underline;
`;
